import { ToastProvider } from '@octano/global-ui';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './assets/scss/styles.scss';
import './config/date';
import './config/language';
import UserProvider from './providers/UserProvider';
import { UserStateProviderLoader } from './providers/UserStateProviderLoader';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <ToastProvider>
      <UserStateProviderLoader>
        {(userData) => (
          <UserProvider user={userData}>
            <App />
          </UserProvider>
        )}
      </UserStateProviderLoader>
    </ToastProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
