import { PaginationType, Table, addToast } from '@octano/global-ui';
import { useMemo, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import TableEmptyContent from '../../../../components/Text/TableEmptyContent';
import { TranslationsKeys } from '../../../../locales/translations';
import { updateGradeReplacementPetition } from '../../api/pendingPetitions';
import { ITEMS_PER_PAGE_DEFAULT } from '../../types/common';
import { rowData } from '../../types/pendingPetitions';
import { useColumns } from '../hooks/columns';
import ChangeGradeModal, { ChangeGradeModalRef } from './ChangeGradeModal';
import { usePetitionsTableLoader } from './PetitionsTableLoader';

export default function PetitionsTable({ isSearch }: { isSearch: boolean }) {
  const { t } = useTranslation(TranslationsKeys.PETITIONS);
  const changeGradeModalRef = useRef<ChangeGradeModalRef>(null);

  const { data, loading, query, updateQuery } = usePetitionsTableLoader();

  const results = useMemo(() => {
    return data?.data ?? [];
  }, [data]);

  const paginationTable = useMemo<PaginationType | undefined>(() => {
    if (data) {
      return {
        totalItems: data.total,
        onChangePage: (page) => updateQuery({ ...query, page }),
        itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
        totalPages: data.total_pages,
        currentPage: query.page,
      };
    } else return undefined;
  }, [data, query, updateQuery]);

  const handleChangeGrade = (rowData: rowData) => {
    changeGradeModalRef.current?.openModal(rowData);
  };

  const handleSubmitGradeChange = useCallback(async (rowData: rowData) => {
    const { status, error } = await updateGradeReplacementPetition(rowData);
    if (error || status !== 200) {
      throw error;
    }
    updateQuery({ ...query, page: 1 });
    return;
  }, []);

  const columns = useColumns(handleChangeGrade);

  return (
    <>
      <Table
        columns={columns}
        data={results}
        isLoadingResults={loading}
        pagination={paginationTable}
        noResultsText={
          <TableEmptyContent
            title={t(isSearch ? 'noResultsSearchTitle' : 'noResultsTitle')}
            subtitle={t(isSearch ? 'noResultsSearchBody' : 'noResultsBody')}
          />
        }
      />
      <ChangeGradeModal
        ref={changeGradeModalRef}
        onSubmit={handleSubmitGradeChange}
      />
    </>
  );
}
