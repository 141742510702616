import {
  ColumnTable,
  OutlinedSelect,
  OutlinedSelectOptionType,
  Icon,
} from '@octano/global-ui';
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../locales/translations';
import { CourseLearningResult, LearningResultTestGradeItem } from '../types';
import dayjs from 'dayjs';

type UseLearningResultAssociateColumnsProps = {
  options?: CourseLearningResult[];
  availableOptions?: CourseLearningResult[];
  editable?: boolean;
  onChangeTestGradeOption?: (
    index: number,
    courseLearningResult: number | null,
  ) => void;
};
export const useLearningResultAssociateColumns = ({
  options = [],
  availableOptions = [],
  editable = false,
  onChangeTestGradeOption,
}: UseLearningResultAssociateColumnsProps) => {
  const { t } = useTranslation(TranslationsKeys.LEARNING_RESULT_ASSOCIATE);

  const mapedOptions = useMemo(
    () =>
      options.map((o) => ({
        label: o.code,
        value: o.id,
      })),
    [options],
  );

  const mapedAvailableOptions = useMemo(
    () =>
      availableOptions.map((o) => ({
        label: o.code,
        value: o.id,
      })),
    [availableOptions],
  );

  const handleChange = useCallback(
    (index: number, courseLearningResult: number | null) => {
      if (!editable || !onChangeTestGradeOption) {
        return;
      }

      onChangeTestGradeOption(index, courseLearningResult);
    },
    [editable, onChangeTestGradeOption],
  );

  const getSelectValue = useCallback(
    (
      row: LearningResultTestGradeItem,
    ): OutlinedSelectOptionType | undefined => {
      const finded = mapedOptions.find(
        (e) => e.value === row.courseLearningResultId,
      );
      return finded ?? undefined;
    },
    [mapedOptions],
  );

  const getSelectOptions = useCallback(
    (row: LearningResultTestGradeItem) => {
      const items = [...mapedAvailableOptions];
      const selected = getSelectValue(row);
      if (selected?.value) {
        return [selected].concat(items);
      }
      return items;
    },
    [mapedAvailableOptions, getSelectValue],
  );

  const columns = useMemo<ColumnTable<LearningResultTestGradeItem>[]>(
    () => [
      {
        headerText: t(`table.date`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        columnName: 'date',
        width: 190,
        cellFormat({ value }) {
          return value ? dayjs(value).format('DD/MM/YYYY') : 'N/D';
        },
      },
      {
        headerText: t(`table.name`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        columnName: 'name',
        width: 190,
        cellFormat({ value }) {
          return value;
        },
      },
      {
        headerText: t(`table.code`),
        tdClassName: 'text-left',
        thClassName: 'text-center',
        columnName: 'code',
        width: 300,
        cellFormat({ row, index }) {
          return (
            <div className="d-flex align-items-center">
              {!!editable && <div style={{ width: 39, height: 39 }} />}
              <div style={{ width: 220 }}>
                <OutlinedSelect
                  name={`${row.testGradeId ?? 0}_${
                    row.testGradeGroupId ?? 0
                  }_ra_id`}
                  label={'RA'}
                  options={getSelectOptions(row)}
                  value={getSelectValue(row)}
                  disabled={!editable}
                  isClearable={false}
                  onChange={(o) => handleChange(index, o?.value ?? null)}
                />
              </div>
              {!!editable && (
                <button
                  type="button"
                  className="btn"
                  onClick={() => handleChange(index, null)}
                >
                  <Icon name="trash" />
                </button>
              )}
            </div>
          );
        },
      },
      {
        headerText: t(`table.description`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        columnName: 'description',
        cellFormat({ row }) {
          return (
            options?.find((o) => o?.id === row.courseLearningResultId)
              ?.description ?? 'N/D'
          );
        },
      },
    ],
    [t, options, handleChange, getSelectValue, getSelectOptions, editable],
  );
  return columns;
};
