import {
  OutlinedSelect,
  Button,
  OutlinedSelectOptionType,
} from '@octano/global-ui';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../locales/translations';

import { useCallback, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { LearningResultFilters } from '../types';
import { getLearningResultsForm } from '../api';

type FormFields = LearningResultFilters<OutlinedSelectOptionType | null>;

const defaultValues: FormFields = {
  period: null,
  section: null,
  course: null,
};

type SearchControlsProps = {
  onFilter?: (filters?: LearningResultFilters<string>) => void;
};

const SearchControls = ({ onFilter }: SearchControlsProps) => {
  const { t } = useTranslation(TranslationsKeys.LEARNING_RESULT_LIST);
  const { control, handleSubmit, reset } = useForm<FormFields>({
    defaultValues,
  });

  const [isMounted, setIsMounted] = useState<boolean>();

  const [periods, setPeriods] = useState<OutlinedSelectOptionType[]>([]);
  const [sections, setSections] = useState<OutlinedSelectOptionType[]>([]);
  const [courses, setCourses] = useState<OutlinedSelectOptionType[]>([]);

  const handleFilter = useCallback(
    (values: FormFields) => {
      !!onFilter &&
        onFilter({
          period: values?.period?.value?.toString() ?? undefined,
          section: values?.section?.value?.toString() ?? undefined,
          course: values?.course?.value?.toString() ?? undefined,
        });
    },
    [onFilter],
  );

  const handleClear = useCallback(() => {
    !!onFilter && onFilter(undefined);
    reset(defaultValues);
  }, [reset, onFilter]);

  useEffect(() => {
    if (!isMounted) {
      getLearningResultsForm().then(({ data }) => {
        setPeriods(data?.periods ?? []);
        setSections(data?.sections ?? []);
        setCourses(data?.courses ?? []);
      });
      setIsMounted(true);
    }
  }, [isMounted]);

  return (
    <Row>
      <Col xs={12} sm={6} md={3}>
        <OutlinedSelect
          label={t(`searchControls.period`)}
          name="period"
          control={control}
          options={periods}
          disabled={!periods?.length}
        />
      </Col>
      <Col xs={12} sm={6} md={3}>
        <OutlinedSelect
          label={t(`searchControls.section`)}
          name="section"
          control={control}
          options={sections}
          disabled={!sections?.length}
        />
      </Col>
      <Col xs={12} sm={6} md={3}>
        <OutlinedSelect
          label={t(`searchControls.course`)}
          name="course"
          control={control}
          options={courses}
          disabled={!courses?.length}
        />
      </Col>
      <Col xs={12} sm={6} md={3}>
        <div
          className="d-flex w-100 align-items-center h-100"
          style={{ gap: 8 }}
        >
          <Button
            text={t(`searchControls.search`)}
            size="md"
            type="button"
            className="flex-fill"
            onClick={handleSubmit(handleFilter)}
          />
          <Button
            text={t(`searchControls.clear`)}
            size="md"
            type="button"
            className="flex-fill"
            outlined
            onClick={handleClear}
          />
        </div>
      </Col>
    </Row>
  );
};

export default SearchControls;
