export enum TranslationsKeys {
  // Olds
  OLD_TRANSLATIONS = 'translation',
  // Globals
  TERMS = 'terms',
  COMMON = 'common',
  // Layouts
  AUTHORIZED_LAYOUT = 'layouts.authorized',
  // learning-result
  LEARNING_RESULT_LIST = 'learning-result/list',
  LEARNING_RESULT_ASSOCIATE = 'learning-result/associate',
  // courses
  COURSES_LIST = 'courses/list',
  COURSES_DETAILS = 'courses/details',
  // Petitions
  PETITIONS = 'grade-replacement-petitions/list',
}

export const TranslatonsArray = Object.values(TranslationsKeys);
