import { useMemo, useCallback } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
  Table,
  ColumnTable,
  TextAreaInput,
  FileInput,
} from '@octano/global-ui';
import { TranslationsKeys } from '../../../locales/translations';

import { LearningResultTestGradeItem } from '../types';

type LearningResultAssociateSummaryProps = {
  row: LearningResultTestGradeItem;
  editable?: boolean;
  onChangeNotes?: (next: string) => void;
  onChangeFile?: (next?: File | null) => void;
};

const LearningResultAssociateSummary = ({
  row,
  editable = false,
  onChangeNotes,
  onChangeFile,
}: LearningResultAssociateSummaryProps) => {
  const { t } = useTranslation(TranslationsKeys.LEARNING_RESULT_ASSOCIATE);

  const columns = useMemo(() => {
    const headers: ColumnTable<any>[] = [
      {
        headerText: '',
        tdClassName: 'text-center fw-700',
        thClassName: 'text-center',
        columnName: 'summary.label',
      },
    ];
    row?.qualitativeAssessments?.forEach((item, i) => {
      headers.push({
        headerText: item?.name ?? t(item.key),
        tdClassName: `text-center ${i === 0 ? 'fw-700' : ''}`,
        thClassName: 'text-center fw-700',
        columnName: item.key,
      });
    });
    return headers;
  }, [t, row?.qualitativeAssessments]);

  const data = useMemo(() => {
    const values: any = {
      'summary.label': t('summary.label'),
    };
    row?.qualitativeAssessments?.forEach((item) => {
      values[item.key] = item.value;
    });
    return [values];
  }, [t, row?.qualitativeAssessments]);

  const linkedFile = useMemo(() => {
    return row?.linkedFile
      ? new File([] as any, row?.linkedFile?.name, {
          lastModified: -1,
        })
      : null;
  }, [row?.linkedFile]);

  const handleDownload = useCallback(() => {
    if (row?.file) {
      const url = URL.createObjectURL(row?.file);
      const a = document.createElement('a');
      a.href = url;
      a.download = row?.file.name;
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } else if (row?.linkedFile && !row?.overrideFile) {
      const link = document.createElement('a');
      link.download = row?.linkedFile.name;
      link.href = row?.linkedFile.url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [row?.linkedFile, row?.overrideFile, row?.file]);

  return (
    <Container fluid>
      <Row className="align-items-center justify-content-center">
        <Col xs={12}>
          <Table
            borderless={false}
            color="transparent"
            data={data}
            columns={columns}
            notResponsive
          />
        </Col>
        <Col className="my-4" xs={12} md={6}>
          <h2 className="text-primary fs-20 fw-700 mb-0">
            {t(`summary.notesLabel`)}
          </h2>
          <TextAreaInput
            name="notes"
            label=""
            placeholder={t(`summary.notesPlaceholder`)}
            value={row.notes ?? ''}
            disabled={!editable}
            onChange={(e) => !!onChangeNotes && onChangeNotes(e.target.value)}
          />
        </Col>
        <Col className="my-4" xs={12} md={6}>
          <FileInput
            name={`file_item_${row.testGradeId ?? 0}_${
              row.testGradeGroupId ?? 0
            }`}
            label={t(`summary.fileLabel`)}
            value={
              row?.file
                ? row?.file
                : row?.linkedFile && !row?.overrideFile
                ? linkedFile
                : null
            }
            btnText={t(`summary.fileBtn`)}
            accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            disabled={!editable}
            onChange={onChangeFile}
            onDownloadFile={
              row?.linkedFile || row?.file ? handleDownload : undefined
            }
          />
        </Col>
      </Row>
    </Container>
  );
};

export default LearningResultAssociateSummary;
