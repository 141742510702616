import { DetailBox } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../locales/translations';

import { LearningResultItem } from '../types';

type SectionDetailsProps = {
  section: LearningResultItem<undefined>;
};

const SectionDetails = ({ section }: SectionDetailsProps) => {
  const { t } = useTranslation(TranslationsKeys.LEARNING_RESULT_ASSOCIATE);

  return (
    <div style={{ width: 'max-content' }}>
      <DetailBox
        columns={[
          {
            title: t('details.courseCode'),
            body: section?.course?.code ?? 'N/D',
          },
          {
            title: t('details.courseName'),
            body: section?.course?.name ?? 'N/D',
          },
          {
            title: t('details.section'),
            body: section?.section?.name ?? 'N/D',
          },
          {
            title: t('details.campus'),
            body: section?.campus?.name ?? 'N/D',
          },
        ]}
      />
    </div>
  );
};

export default SectionDetails;
