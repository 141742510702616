import React from 'react';
import { TextInput } from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../../locales/translations';
import useValidatorNotes from '../hooks/useValidatorNotes';

export interface ChangeGradeFormData {
  newGrade: string;
  studentName: string;
  typeName: string;
  courseName: string;
  sectionName: string;
  title: string;
  grade: string;
}

const ChangeGradeForm: React.FC = () => {
  const { t } = useTranslation(TranslationsKeys.PETITIONS);
  const { control, watch } = useFormContext<ChangeGradeFormData>();
  const { formattertNote } = useValidatorNotes();

  const typeName = watch('typeName');
  const isHabilitaciones = typeName === 'Habilitaciones';

  return (
    <>
      <Row className="mb-3">
        <Col xs={12} md={6}>
          <TextInput
            name="studentName"
            label={t('modals.changeGrade.studentName')}
            control={control}
            disabled
          />
        </Col>
        <Col xs={12} md={6}>
          <TextInput
            name="typeName"
            label={t('modals.changeGrade.petitionType')}
            control={control}
            readOnly
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={12} md={6}>
          <TextInput
            name="courseName"
            label={t('modals.changeGrade.course')}
            control={control}
            disabled
          />
        </Col>
        <Col xs={12} md={6}>
          <TextInput
            name="sectionName"
            label={t('modals.changeGrade.section')}
            control={control}
            disabled
          />
        </Col>
      </Row>

      {!isHabilitaciones && (
        <Row className="mb-3">
          <Col>
            <TextInput
              name="title"
              label={t('modals.changeGrade.grade')}
              control={control}
              disabled
            />
          </Col>
        </Row>
      )}

      <Row className="mb-3">
        <Col xs={12} md={6}>
          <TextInput
            name="grade"
            label={t('modals.changeGrade.originalGrade')}
            control={control}
            disabled
          />
        </Col>
        <Col xs={12} md={6}>
          <TextInput
            name="newGrade"
            label={t('modals.changeGrade.newGrade')}
            control={control}
            rules={{
              required: t('modals.changeGrade.newGradeRequired') as string,
              validate: (value: string) => {
                return (
                  formattertNote(value) !== '' ||
                  (t('modals.changeGrade.invalidGrade') as string)
                );
              },
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default ChangeGradeForm;
