import { useCallback } from 'react';
import { SidenavProps, SidenavLinkType } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../locales/translations';

export function useSidenavLinks({
  modulesHidden,
}: {
  modulesHidden?: string[] | null;
}): SidenavProps['links'] {
  const { t } = useTranslation(TranslationsKeys.AUTHORIZED_LAYOUT);

  const showModule = useCallback(
    (code: string, module: SidenavLinkType) => {
      return !modulesHidden?.includes(code) ? [module] : [];
    },
    [modulesHidden],
  );

  return [
    {
      icon: 'chalkboard',
      name: t('sidenav.myClasses'),
      links: [
        {
          path: '/classes/start',
          name: t('sidenav.classesStart'),
          icon: 'chalkboard',
        },
        {
          path: '/classes/history',
          name: t('sidenav.classesHistory'),
          icon: 'classes_history',
        },
        {
          path: '/classes/untracked-attendance',
          name: t('sidenav.classesUntrackedAttendance'),
          icon: 'class_untracked_attendance',
        },
        {
          path: '/reports',
          name: t('sidenav.reports'),
          icon: 'certificates_requests',
        },
        {
          path: '/classes/tracking',
          name: t('sidenav.classesTracking'),
          icon: 'attendance_tracking',
        },
      ],
    },
    {
      icon: 'packages',
      name: t('sidenav.sectionsAndCourses'),
      links: [
        {
          path: '/courses',
          name: t('sidenav.courses'),
          icon: 'packages',
        },
        {
          path: '/grade-entry/list',
          name: t('sidenav.gradeEntryList'),
          icon: 'grade_entry',
        },
        ...showModule('learningResult', {
          path: '/learning-result',
          name: t('sidenav.learningResult'),
          icon: 'search_document',
        }),
        ...showModule('gradeReplacementList', {
          path: '/grade-replacement-petitions',
          name: t('sidenav.gradeReplacementList'),
        }),
      ],
    },
    {
      path: '/period-end',
      name: t('sidenav.periodEnd'),
      icon: 'schedule_upload',
    },
  ];
}
