import { Button } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Col, Row } from 'reactstrap';
import { TranslationsKeys } from '../../../../locales/translations';

const CourseFooterSection = () => {
  const { t } = useTranslation(TranslationsKeys.COURSES_DETAILS);
  const history = useHistory();

  return (
    <Row className="py-5 justify-content-end pt-5">
      <Col xs={12} lg={3} className="pb-2 order-2 order-lg-1">
        <Button
          type="button"
          outlined
          onClick={() => history.goBack()}
          text={t(`common:actions.back`)}
          fullwidth
        />
      </Col>
    </Row>
  );
};

export default CourseFooterSection;
