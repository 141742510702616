import { Table } from '@octano/global-ui';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import SectionTitle from '../../../../components/Text/SectionTitle';
import TableEmptyContent from '../../../../components/Text/TableEmptyContent';
import { TranslationsKeys } from '../../../../locales/translations';
import { useColumnsCompatibilities } from '../../hooks/useColumnsCompatibilities';
import { CourseFormFields } from '../../types/details';

const CourseCompatibilitiesSection = () => {
  const { t } = useTranslation(TranslationsKeys.COURSES_DETAILS);
  const { control } = useFormContext<CourseFormFields>();

  const { fields: compatibilities = [] } = useFieldArray({
    control,
    name: 'compatibilities',
  });

  const { columns: columnsCompatibilities } = useColumnsCompatibilities();

  return (
    <>
      <Row>
        <Col
          xs={12}
          className="d-flex justify-content-between align-items-center pt-4 pb-4"
        >
          <SectionTitle text={t(`compatibilities`)} />
        </Col>
        <Col xs={12}>
          <Table
            columns={columnsCompatibilities}
            data={compatibilities}
            noResultsText={
              <TableEmptyContent
                title={t(`titleEmptyCompatibilities`)}
                subtitle={''}
              />
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default CourseCompatibilitiesSection;
