import { useContext } from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import { CheckInput } from '@octano/global-ui';

import SectionTitle from '../../../../components/Text/SectionTitle';
import { TranslationsKeys } from '../../../../locales/translations';
import { CourseFormContext } from '../../provider/CourseProvider';
import { CourseFormFields } from '../../types/details';

const CourseActivityTypeSection = () => {
  const { t } = useTranslation(TranslationsKeys.COURSES_DETAILS);

  const { control } = useFormContext<CourseFormFields>();

  const { formData } = useContext(CourseFormContext);
  const { activities = [] } = formData;

  if (!activities?.length) {
    return <></>;
  }

  return (
    <Row>
      <Col xs={12} className="pt-4">
        <SectionTitle text={t(`activityTypes`)} />
      </Col>
      <Col xs={12}>
        <Row className="px-md-3 py-2">
          <Controller
            control={control}
            name="attendance"
            render={({ field }) => (
              <>
                {activities.map((activity) => (
                  <Col
                    xs={6}
                    md={4}
                    lg={3}
                    xl={2}
                    key={`activity_${activity.value}`}
                  >
                    <CheckInput
                      name={`activity_${activity.value}`}
                      label={activity.label}
                      value={
                        !!field?.value?.some(
                          (e) => e.activityTypeId === activity.value,
                        )
                      }
                      disabled
                    />
                  </Col>
                ))}
              </>
            )}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default CourseActivityTypeSection;
