import { ColumnTable } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { format } from 'rut.js';
import { TranslationsKeys } from '../../../../locales/translations';

export const useColumns = () => {
  const { t } = useTranslation(TranslationsKeys.PETITIONS);

  const columns: ColumnTable<any>[] = [
    {
      headerText: t(`table.columns.folio`),
      columnName: 'folio',
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      headerText: t(`table.columns.nameAndId`),
      columnName: 'studentName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '15%',
      cellFormat: ({ row }) => {
        return (
          <p>
            {row?.studentName}
            <br />
            {row?.studentRun && format(row.studentRun)}
          </p>
        );
      },
    },
    {
      headerText: t(`table.columns.typeName`),
      columnName: 'typeName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      headerText: t(`table.columns.createdAt`),
      columnName: 'createdAt',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '10%',
      cellFormat: (options) => dayjs(options.value).format('DD/MM/YYYY'),
    },
    {
      headerText: t(`table.columns.period`),
      columnName: 'periodName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '10%',
    },
    {
      headerText: t(`table.columns.section`),
      columnName: 'sectionName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '10%',
    },
    {
      headerText: t(`table.columns.courseName`),
      columnName: 'courseName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '15%',
    },
    {
      headerText: t(`table.columns.completedAt`),
      columnName: 'completedAt',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '10%',
      cellFormat: (options) => dayjs(options.value).format('DD/MM/YYYY'),
    },
    {
      headerText: t(`table.columns.oldGrade`),
      columnName: 'grade',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      bold: true,
      width: '15%',
    },
    {
      headerText: t(`table.columns.newGrade`),
      columnName: 'newGrade',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      bold: true,
      width: '15%',
    },
  ];

  return columns;
};
