import { Table } from '@octano/global-ui';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import SectionTitle from '../../../../components/Text/SectionTitle';
import TableEmptyContent from '../../../../components/Text/TableEmptyContent';
import { TranslationsKeys } from '../../../../locales/translations';
import { useColumnsPrerequisites } from '../../hooks/useColumnsPrerequisites';
import { CourseFormFields } from '../../types/details';

const CoursePrerequisitesSection = () => {
  const { t } = useTranslation(TranslationsKeys.COURSES_DETAILS);
  const { control } = useFormContext<CourseFormFields>();

  const { fields: prerequisites = [] } = useFieldArray({
    control,
    name: 'prerequisites',
  });

  const { columns: columnsPrerequisites } = useColumnsPrerequisites();

  return (
    <>
      <Row>
        <Col
          xs={12}
          className="d-flex justify-content-between align-items-center pt-4 pb-4"
        >
          <SectionTitle text={t(`prerequisites`)} />
        </Col>
        <Col xs={12}>
          <Table
            columns={columnsPrerequisites}
            data={prerequisites}
            noResultsText={
              <TableEmptyContent
                title={t(`titleEmptyPrerequisites`)}
                subtitle={t('')}
              />
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default CoursePrerequisitesSection;
