import { ColumnTable, TextAreaInput } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../locales/translations';
import { CourseLearningResult } from '../types/details';

export const useColumnsLearningResults = () => {
  const { t } = useTranslation(TranslationsKeys.COURSES_DETAILS);
  return useMemo<ColumnTable<CourseLearningResult>[]>(() => {
    return [
      {
        columnName: 'code',
        thClassName: 'text-center',
        tdClassName: 'text-center font-weight-bold',
        headerText: t(`learningResultCode`),
      },
      {
        columnName: 'description',
        thClassName: 'text-center',
        tdClassName: 'text-center p-0 ',
        headerText: t(`learningResultDescription`),
        cellFormat: ({ row }) => {
          return (
            <div className="d-flex align-items-center" style={{ height: '10' }}>
              <TextAreaInput
                height="10"
                className="w-100"
                label=""
                value={row.description}
                name={'description'}
                disabled={true}
              />
            </div>
          );
        },
      },
    ];
  }, [t]);
};
