import { COURSES } from '../../../api/endpoints';
import request from '../../../api/request';
import {
  CoursesFeaturesOptions,
  SchoolType,
  AcademicLevelListItem,
  CourseAssistanceRequirements,
} from '../types/details';

interface CourseList {
  items: number;
  page: number;
  code?: string;
  name?: string;
}

export const getCoursesTeacherPortalRequest = ({
  items,
  page,
  code,
  name,
}: CourseList) => {
  const URL = `${COURSES.BASE}`;
  const params = {
    items_per_page: items,
    page: page,
    code,
    name,
  };
  return request<any>(URL, {
    method: 'get',
    params,
  });
};

// Details
interface SchoolsT {
  schools: SchoolType[];
  activities: {
    id: number;
    name: string;
    createdAt: Date;
    updatedAt: Date;
  }[];
  academicLevels: AcademicLevelListItem[];
  courseTypes: { id: number; name: string }[];
  studyPlanVersions: { id: number; name: string }[];
  modalities: { id: number; name: string }[];
  coursesFeatures?: CoursesFeaturesOptions | null;
}

interface CoursesT {
  id: number;
  name: string;
  priority: number;
}

export const getSchoolListRequest = () => {
  return request<SchoolsT>(COURSES.FORM_DATA);
};

export const requestCourseTypes = () => {
  return request<{ data: CoursesT[] }>(`${COURSES.COURSE_TYPES}`);
};

export const requestGetCourse = (courseId: number) => {
  return request<{ data: CourseAssistanceRequirements }>(
    `${COURSES.GET_COURSE(courseId)}`,
  );
};
