import { ColumnTable, Icon } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../locales/translations';
import BadgeStatus from '../parts/BadeStatus';
import { LearningResultItem } from '../types';
import { useHistory } from 'react-router-dom';
import { PathsLayouts } from '../../../config/routes';

type UseLearningResultColumnsProps = {
  onDownloadReport?: (sectionId: number) => void;
};

export const useLearningResultColumns = ({
  onDownloadReport,
}: UseLearningResultColumnsProps) => {
  const { t } = useTranslation(TranslationsKeys.LEARNING_RESULT_LIST);
  const history = useHistory();

  const columns = useMemo<ColumnTable<LearningResultItem<any>>[]>(
    () => [
      {
        headerText: t(`table.period`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        columnName: 'period',
        cellFormat({ value }) {
          return value?.name;
        },
      },
      {
        headerText: t(`table.section`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        columnName: 'section',
        cellFormat({ value }) {
          return value?.name;
        },
      },
      {
        headerText: t(`table.courseName`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        columnName: 'course',
        cellFormat({ value }) {
          return value?.name;
        },
      },
      {
        headerText: t(`table.courseCode`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        columnName: 'course',
        cellFormat({ value }) {
          return value?.code;
        },
      },
      {
        headerText: t(`table.school`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        columnName: 'school',
        cellFormat({ value }) {
          return value?.name;
        },
      },
      {
        headerText: t(`table.campus`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        columnName: 'campus',
        cellFormat({ value }) {
          return value?.name;
        },
      },
      {
        headerText: t(`table.status`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        columnName: 'status',
        cellFormat({ value }) {
          return <BadgeStatus status={value} />;
        },
      },
      {
        headerText: t(`table.edit`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        columnName: 'section',
        width: 70,
        cellFormat({ value }) {
          return (
            <button
              type="button"
              className="btn"
              onClick={() =>
                history.push(`${PathsLayouts.learningResult}/${value.id}`)
              }
            >
              <Icon name="edit" />
            </button>
          );
        },
      },
      {
        headerText: t(`table.report`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        columnName: 'report',
        width: 70,
        cellFormat({ row }) {
          return (
            <button
              className="btn"
              onClick={() =>
                !!onDownloadReport && onDownloadReport(row.section.id)
              }
            >
              <Icon name="download" />
            </button>
          );
        },
      },
    ],
    [t, history, onDownloadReport],
  );
  return columns;
};
