import { ColumnTable } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TranslationsKeys } from '../../../locales/translations';
import { CourseCompatibility } from '../types/details';

export const useColumnsCompatibilities = () => {
  const { t } = useTranslation(TranslationsKeys.COURSES_DETAILS);

  const columns: ColumnTable<CourseCompatibility>[] = useMemo(() => {
    return [
      {
        columnName: 'shortening',
        width: '30%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`compatibilityShortening`),
      },
      {
        columnName: 'name',
        width: '40%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`compatibilityName`),
      },
      {
        columnName: 'credits',
        width: '15%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`compatibilityCredits`),
      },
    ];
  }, [t]);

  return { columns };
};
