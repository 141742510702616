import { PaginationType, Table } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import TableEmptyContent from '../../../../components/Text/TableEmptyContent';
import { TranslationsKeys } from '../../../../locales/translations';
import { ITEMS_PER_PAGE_DEFAULT } from '../../types/common';
import { useColumns } from '../hooks/columns';
import { usePetitionsTableLoader } from './PetitionsTableLoader';

export default function PetitionsTable({ isSearch }: { isSearch: boolean }) {
  const { t } = useTranslation(TranslationsKeys.PETITIONS);

  const { data, loading, query, updateQuery } = usePetitionsTableLoader();

  const results = useMemo(() => {
    return data?.data ?? [];
  }, [data]);

  const paginationTable = useMemo<PaginationType | undefined>(() => {
    if (data) {
      return {
        totalItems: data.total,
        onChangePage: (page) => updateQuery({ ...query, page }),
        itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
        totalPages: data.total_pages,
        currentPage: query.page,
      };
    } else return undefined;
  }, [data, query, updateQuery]);

  const columns = useColumns();

  return (
    <>
      <Table
        columns={columns}
        data={results}
        isLoadingResults={loading}
        pagination={paginationTable}
        noResultsText={
          <TableEmptyContent
            title={t(isSearch ? 'noResultsSearchTitle' : 'noResultsTitle')}
            subtitle={t(isSearch ? 'noResultsSearchBody' : 'noResultsBody')}
          />
        }
      />
    </>
  );
}
