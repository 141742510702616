import { Dialog as DialogGlobal } from '@octano/global-ui';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';

import Dialog from './components/Modals/Dialog';
import AppVersion from './components/version/AppVersion';
import routes from './config/routes';

function App() {
  return (
    <>
      <AppVersion />
      <Router>
        <Switch>
          {Object.entries(routes)?.map(([key, value]) => {
            const Layout = value.layoutComponent;
            return (
              <Route
                key={key}
                path={key}
                render={(props: any) => <Layout {...props} />}
              />
            );
          })}
          <Redirect from="*" to="/auth" />
        </Switch>
      </Router>
      <Dialog />
      <DialogGlobal />
    </>
  );
}

export default App;
