import { Course } from '../../../types/Section';
import { CourseTable } from '../types';

export function adapterCourseData(courseResponseData: Course): CourseTable {
  const {
    id,
    name,
    code,
    credits,
    school,
    modality,
    ...data
  } = courseResponseData;

  const studyplan = data.semesterCourses
    ? data.semesterCourses.map(
        (sC) => sC.semester.curriculum.studyPlanVersion.name,
      )
    : [];

  return {
    id,
    code: code,
    name: name,
    school: school?.name ?? '-',
    studyplan: studyplan,
    modality: modality?.name ?? '-',
    credits: credits,
  };
}
