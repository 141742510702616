import {
  Button,
  OutlinedSelect,
  OutlinedSelectOptionType,
  SearchBox,
  useMobile,
  addToast,
} from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import Loading from '../../../../components/Info/Loading';
import { useValidations } from '../../../../hooks/useValidations';

import { TranslationsKeys } from '../../../../locales/translations';
import { downloadFromBlob } from '../../../../utils/blob';
import { ITEMS_PER_PAGE_DEFAULT } from '../../../courses/provider/CoursesLoader';
import {
  downloadPetitions,
  getGradeReplacementPetitionsFormFields,
} from '../../api/pendingPetitions';
import { PetitionGradeProcessStatus } from '../../enums/petition-grade-process-status.enum';
import { HandleSearch, SearchParams } from '../../types/common';

export type LearningResultFilters<T> = {
  period?: T;
  section?: T;
  course?: T;
  petitionType?: T;
  search?: string;
};

type FormFields = LearningResultFilters<OutlinedSelectOptionType | null>;

const defaultValues: FormFields = {
  period: null,
  section: null,
  course: null,
  petitionType: null,
  search: '',
};

interface SearchControlsProps {
  onSearch?: ({ searchParams, page }: HandleSearch) => void;
  setFilters?: (filters?: SearchParams) => void;
}

const SearchControls = ({
  onSearch = () => null,
  setFilters = (filters?: LearningResultFilters<string>) => {},
}: SearchControlsProps) => {
  const { t } = useTranslation(TranslationsKeys.PETITIONS);
  const isMobile = useMobile();

  const { handleSubmit, control, reset, setValue } = useForm<FormFields>({
    defaultValues,
  });
  const { validateMinLength } = useValidations();

  const [isMounted, setIsMounted] = useState<boolean>();

  const [periods, setPeriods] = useState<OutlinedSelectOptionType[]>([]);
  const [sections, setSections] = useState<OutlinedSelectOptionType[]>([]);
  const [courses, setCourses] = useState<OutlinedSelectOptionType[]>([]);
  const [petitionTypes, setPetitionTypes] = useState<
    OutlinedSelectOptionType[]
  >([]);
  const [loadingReport, setLoadingReport] = useState<boolean>(false);

  const handleFilter = useCallback(
    (values: FormFields) => {
      const searchParams = {
        period: values?.period?.value?.toString() ?? undefined,
        section: values?.section?.value?.toString() ?? undefined,
        course: values?.course?.value?.toString() ?? undefined,
        petitionType: values?.petitionType?.value?.toString() ?? undefined,
        search: values.search?.trim() !== '' ? values.search : undefined,
        hasFilters: Object.values(values).some((value) => value !== null),
      };

      setFilters({
        ...searchParams,
      });

      onSearch({ searchParams, page: 1 });
    },
    [setFilters, onSearch],
  );

  const handleClear = useCallback(() => {
    !!setFilters && setFilters(undefined);
    reset(defaultValues);
    onSearch({
      searchParams: {},
      page: 1,
    });
  }, [reset, setFilters, onSearch]);

  const findForm = useCallback(async () => {
    const { data, error } = await getGradeReplacementPetitionsFormFields();
    if (data && !error) {
      setPeriods(data?.periods ?? []);
      setCourses(data?.courses ?? []);
      setSections(data?.sections ?? []);
      setPetitionTypes(data?.petitionTypes ?? []);
      setValue(
        'period',
        data?.periods?.find((p) => p.value === data?.currentPeriod.id) ?? null,
      );
    }
    setIsMounted(true);
  }, [setValue]);

  const handleDocumentDownload = useCallback(
    async (values: FormFields) => {
      setLoadingReport(true);
      const searchParams = {
        period: values?.period?.value?.toString() ?? undefined,
        section: values?.section?.value?.toString() ?? undefined,
        course: values?.course?.value?.toString() ?? undefined,
        petitionType: values?.petitionType?.value?.toString() ?? undefined,
        search: values.search?.trim() !== '' ? values.search : undefined,
        gradeProcessStatus: PetitionGradeProcessStatus.Completed,
      };

      const res = await downloadPetitions({
        items: ITEMS_PER_PAGE_DEFAULT,
        page: 0,
        ...searchParams,
      });

      setLoadingReport(false);

      if (res.error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`report.error`),
        });
      } else if (res.status === 204) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`report.empty`),
        });
      } else {
        const blob = new Blob([res.data], { type: 'vnd.ms-excel' });
        downloadFromBlob(blob, `${t(`report.completedName`)}.xlsx`);
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`report.success`),
        });
      }
    },
    [t],
  );

  useEffect(() => {
    if (!isMounted) {
      findForm();
    }
  }, [isMounted, findForm]);

  if (!isMounted) {
    return <Loading insideCard />;
  }

  return (
    <>
      <Row>
        <Col xs={12} sm={6} md={4}>
          <OutlinedSelect
            label={t(`searchControls.period`)}
            name="period"
            control={control}
            options={periods}
            disabled={!periods?.length}
          />
        </Col>
        <Col xs={12} sm={6} md={4}>
          <SearchBox
            label={t(`searchControls.nameAndId`)}
            name="search"
            placeholder={t(`searchControls.placeholderNameAndId`)}
            control={control}
            rules={{
              validate: {
                minLength: validateMinLength(3),
              },
            }}
          />
        </Col>
        <Col xs={12} sm={6} md={4}>
          <OutlinedSelect
            label={t(`searchControls.section`)}
            name="section"
            control={control}
            options={sections}
            disabled={!sections?.length}
          />
        </Col>
        <Col xs={12} sm={6} md={4}>
          <OutlinedSelect
            label={t(`searchControls.course`)}
            name="course"
            control={control}
            options={courses}
            disabled={!courses?.length}
          />
        </Col>
        <Col xs={12} sm={6} md={4}>
          <OutlinedSelect
            label={t(`searchControls.petitionType`)}
            name="petitionType"
            control={control}
            options={petitionTypes}
            disabled={!petitionTypes?.length}
          />
        </Col>

        <Col
          xs={12}
          md={{ size: 2, offset: 0 }}
          lg={{ size: 2, offset: 0 }}
          style={{ marginTop: isMobile ? 0 : '1.5rem' }}
        >
          <Button
            text={t(`searchControls.search`)}
            size="md"
            type="submit"
            className="flex-fill"
            fullwidth
            onClick={handleSubmit(handleFilter)}
          />
        </Col>
        <Col
          xs={12}
          md={{ size: 2 }}
          lg={2}
          style={{ marginTop: isMobile ? 0 : '1.5rem' }}
        >
          <Button
            text={t(`searchControls.clear`)}
            size="md"
            type="button"
            className="flex-fill"
            outlined
            fullwidth
            onClick={handleClear}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={{ size: 4, offset: 8 }}>
          <Button
            icon="download"
            text={t(`searchControls.download`)}
            size="md"
            type="button"
            className="flex-fill"
            outlined
            fullwidth
            onClick={handleSubmit(handleDocumentDownload)}
            disabled={!periods?.length}
            loading={loadingReport}
          />
        </Col>
      </Row>
    </>
  );
};

export default SearchControls;
