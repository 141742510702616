import { Card, Row, Col, Container } from 'reactstrap';
import { Table, addToast } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useCallback, useState, useEffect } from 'react';

import { TranslationsKeys } from '../../locales/translations';
import { useLearningResultColumns } from './hooks/useLearningResultColumns';
import SearchControls from './parts/SearchControls';
import { LearningResultFilters, LearningResultItem } from './types';
import { downloadSectionReport, getPaginatedLearningResults } from './api';
import { downloadFromBlob } from '../../utils/blob';

const ITEMS_PER_PAGE = 10;

const LearningResultList = () => {
  const { t } = useTranslation(TranslationsKeys.LEARNING_RESULT_LIST);

  const [data, setData] = useState<LearningResultItem<undefined>[]>([]);
  const [filters, setFilters] = useState<LearningResultFilters<string>>();
  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [downloading, setDownloadLoading] = useState<boolean>(false);

  const handleDownload = useCallback(
    async (sectionId: number) => {
      if (downloading) {
        return;
      }
      setDownloadLoading(true);
      const response = await downloadSectionReport(sectionId);
      const { data, error } = response;

      if (error || !data) {
        addToast({
          text: t(`report.error`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        addToast({
          text: t(`report.success`),
          color: 'success',
          icon: 'success',
        });
        const blob = new Blob([data], {
          type: 'vnd.ms-excel',
        });
        downloadFromBlob(blob, `resultados.xls`);
      }
      setDownloadLoading(false);
    },
    [downloading, t],
  );

  const columns = useLearningResultColumns({
    onDownloadReport: handleDownload,
  });

  const fetchData = useCallback(
    async (nextPage: number, nextFilters?: LearningResultFilters<string>) => {
      if (loading) {
        return;
      }
      setLoading(true);
      const { data, error } = await getPaginatedLearningResults({
        ...nextFilters,
        page: nextPage,
        items_per_page: ITEMS_PER_PAGE,
      });

      setFilters(nextFilters);
      setPage(nextPage);

      if (!error && data) {
        setData(data.data);
        setTotalItems(data.total);
        setTotalPages(data.total_pages);
      } else {
        //
      }
      setLoading(false);
    },
    [loading],
  );

  useEffect(() => {
    if (!isMounted) {
      fetchData(0, undefined);
      setIsMounted(true);
    }
  }, [isMounted, fetchData]);

  return (
    <>
      <Card className="m-3 py-4">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <p className="text-light fs-14 mb-5">{t(`description`)}</p>
            </Col>
          </Row>
          <SearchControls
            onFilter={(nextFilters) => fetchData(0, nextFilters)}
          />
          <Row className="mt-3">
            <Col xs={12}>
              <Table
                data={data}
                columns={columns}
                isLoadingResults={loading}
                pagination={{
                  totalPages: totalPages,
                  totalItems: totalItems,
                  itemsPerPage: ITEMS_PER_PAGE,
                  currentPage: page,
                  onChangePage: (nextPage) => fetchData(nextPage, filters),
                }}
              />
            </Col>
          </Row>
        </Container>
      </Card>
    </>
  );
};

export default LearningResultList;
