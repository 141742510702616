import { PaginationType, Table } from '@octano/global-ui';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableEmptyContent from '../../components/Text/TableEmptyContent';
import { TranslationsKeys } from '../../locales/translations';
import { adapterCourseData } from './adapter/adapterResponseList';
import useColumnsCoursesList from './hooks/useColumnsCoursesList';
import {
  ITEMS_PER_PAGE_DEFAULT,
  useCoursesLoader,
} from './provider/CoursesLoader';
import { SearchControls, SearchParams } from './parts/SearchControls';

interface HandleSearch {
  searchParams: SearchParams;
  page: number;
}

const CourseList = () => {
  const { t } = useTranslation(TranslationsKeys.COURSES_LIST);
  const { data, loading, query, updateQuery } = useCoursesLoader();

  const [filters, setFilters] = useState<SearchParams>({});
  const columns = useColumnsCoursesList();

  const results = useMemo(() => {
    return data?.data.map(adapterCourseData) ?? [];
  }, [data]);

  const paginationTable = useMemo<PaginationType | undefined>(() => {
    if (data) {
      return {
        totalItems: data.total,
        onChangePage: (page) => updateQuery({ ...query, page }),
        itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
        totalPages: data.total_pages,
        currentPage: query.page,
      };
    } else return undefined;
  }, [data, query, updateQuery]);

  const isSearch = useMemo(() => {
    return filters?.hasFilters ?? false;
  }, [filters]);

  const handleSearch = useCallback(
    async ({ searchParams, page = 1 }: HandleSearch) => {
      updateQuery({
        page: page,
        code: searchParams?.code,
        name: searchParams?.name,
      });
    },
    [updateQuery],
  );

  return (
    <div className="m-3 py-4 px-3 bg-white rounded">
      <h2 className="text-primary fs-20 fw-700 mt-2">{t('title')}</h2>
      <p className="fs-16 mt-3 mb-4">{t('subtitle')}</p>

      <SearchControls onSearch={handleSearch} setFilters={setFilters} />

      <Table
        columns={columns}
        data={results}
        isLoadingResults={loading}
        pagination={paginationTable}
        noResultsText={
          <TableEmptyContent
            title={t(isSearch ? 'noResultsSearchTitle' : 'noResultsTitle')}
            subtitle={t(isSearch ? 'noResultsSearchBody' : 'noResultsBody')}
          />
        }
      />
    </div>
  );
};

export default CourseList;
