const AUTH = {
  CREDENTIAL_LOGIN: '/credential-login',
  REQUEST_ACTIVATION_EMAIL: '/check-activate-account',
  REQUEST_FORGOT_PASSWORD_EMAIL: '/recovery-password',
  ACTIVATE_ACCOUNT: '/activate-account',
  CHANGE_PASSWORD: '/reset-password',
  CHECK_PASSWORD_CHANGE_TOKEN: '/check-token',
};

const BASE = {
  BASE: '/teacher-portal',
};

const CLASSES = {
  BASE: '/lessons',
  SEARCH: '/lessons/search',
  RECOVER: '/lessons/recovery',
  GET_UNTRACKED_CLASSES: (teacherId: number | string) =>
    `/lessons/no-assistance/lessons/${teacherId}`,
  GET_CLASS_STATUS: (classId: number | string) => `/lessons/${classId}`,
  PUT_CLASS_STATUS: (classId: number | string) => `/lessons/${classId}`,
};

const ATTENDANCE = {
  BASE: '/assistance',
};

const SETTINGS = {
  TIMEZONE: '/settings/timezone',
};

const SECTIONS = {
  BASE: '/section',
  STUDENTS: '/students',
};

const TIME_MODULES = {
  BASE: '/teacher-portal/time-modules',
  ALL: '/teacher-portal/time-modules/all',
};

const POSTULANTS = {
  BASE: '/postulants',
};

const GRADE_ENTRY = {
  BASE: (sectionId: number | string) => `/grades-entry/${sectionId}`,
  TEACHER_SECTION_LIST: '/grades-entry/teacher-sections-list',
  SECTION_COURSE: '/grades-entry/section-course',
  GET_STUDENT: (id: number) =>
    `/teacher-portal/evaluation-grade-entry/${id}/students`,
  CONFIG: '/teacher-portal/grades-entry-config',
  DELETE_PARTIAL_EVALUATION: '/grades-entry',
  POST_STUDENT_TEST_GRADE: '/grades-entry/students/test-grades',
  POST_STUDENT_FINAL_GRADE: '/grades-entry/students/final-grade',
  SECTION_GRADE_CONFIG: (sectionId: number | string) =>
    `teacher-portal/sections-grade-config/${sectionId}`,
};

const REPORTS = {
  BASE: '/report/teacher',
  DOWNLOAD_BY_URL: (url: string) => `/report${url}`,
  UPLOAD_DATA_BY_URL: (url: string) => `/report${url}`,
};

const TEACHER_PORTAL_SECTIONS = {
  BASE: '/teacher-portal',
  FILTER_GET_SECTION: '/teacher-portal/sections/filter/form',
  GET_PERIODS: '/teacher-portal/sections/find',
  GET_SECTION: `/teacher-portal/sections/`,
  GET_ALL_SECTION: `/teacher-portal/sections/all`,
  GET_SECTION_BY_ID: (sectionID: number) =>
    `/teacher-portal/sections/${sectionID}`,
  POST_CLOSED_SECTION: (sectionId: number) =>
    `/teacher-portal/sections/${sectionId}`,
  DOWNLOAD_SECTION_CLOSED: (sectionId: number) =>
    `/teacher-portal/sections/${sectionId}/download`,
  GET_STATUS: (sectionId: number | string) =>
    `/teacher-portal/sections/${sectionId}/status`,
};

const LEARNING_RESULTS = {
  FORM: '/teacher-portal/learning-results/form',
  GET_PAGINATED_ITEMS: '/teacher-portal/learning-results',
  ASSOCIATE_FORM: (sectionId: string | number) =>
    `/teacher-portal/learning-results/test-grades-form/${sectionId}`,
  ASSOCIATE: (sectionId: string | number) =>
    `/teacher-portal/learning-results/test-grades/${sectionId}`,
  DOWNLOAD_SECTION: (sectionId: string | number) =>
    `/teacher-portal/learning-results/test-grades-download/${sectionId}`,
};

const COURSES = {
  BASE: `/teacher-portal/courses`,
  // Details
  GET_COURSE: (courseId: number) => `/teacher-portal/courses/${courseId}`,
  FORM_DATA: '/teacher-portal/courses/form-data',
  COURSE_TYPES: '/teacher-portal/courses/type/all',
};

export {
  AUTH,
  BASE,
  CLASSES,
  SETTINGS,
  SECTIONS,
  TIME_MODULES,
  ATTENDANCE,
  POSTULANTS,
  GRADE_ENTRY,
  REPORTS,
  TEACHER_PORTAL_SECTIONS,
  LEARNING_RESULTS,
  COURSES,
};
