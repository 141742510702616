import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { TranslationsKeys } from '../../../../locales/translations';
import GoBackButton from '../../../../components/Button/GoBackButton';

type CourseHeaderSectionProps = {
  onBack?: () => void;
};

const CourseHeaderSection = ({ onBack }: CourseHeaderSectionProps) => {
  const { t } = useTranslation(TranslationsKeys.COURSES_DETAILS);

  return (
    <Row>
      <Col xs={12} lg={8}>
        <GoBackButton
          onClick={onBack}
          text={t(`common:actions.backToPrevStep`)}
        />
      </Col>
    </Row>
  );
};

export default CourseHeaderSection;
