import { PetitionGradeProcessStatus } from '../enums/petition-grade-process-status.enum';

export type SearchParams = {
  period?: string;
  section?: string;
  course?: string;
  petitionType?: string;
  name?: string;
  hasFilters?: boolean;
  gradeProcessStatus?: PetitionGradeProcessStatus;
};

export type HandleSearch = {
  searchParams?: SearchParams;
  page: number;
};

export const ITEMS_PER_PAGE_DEFAULT = 10;
