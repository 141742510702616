import { Table } from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import SectionTitle from '../../../../components/Text/SectionTitle';
import TableEmptyContent from '../../../../components/Text/TableEmptyContent';
import { TranslationsKeys } from '../../../../locales/translations';
import { useColumnsLearningResults } from '../../hooks/useColumnsLearningResults';
import { CourseFormFields } from '../../types/details';

const LearningResultSection = () => {
  const { t } = useTranslation(TranslationsKeys.COURSES_DETAILS);

  const { watch } = useFormContext<CourseFormFields>();
  const learningResults = watch('learningResults');

  const columns = useColumnsLearningResults();

  return (
    <>
      <Row>
        <Col
          xs={12}
          className="d-flex justify-content-between align-items-center p-4"
        >
          <SectionTitle text={t(`learningResult`)} />
        </Col>
        <Col xs={12}>
          <Table
            columns={columns}
            data={learningResults ?? []}
            noResultsText={
              <TableEmptyContent
                title={t(`titleEmptyCompatibilities`)}
                subtitle={''}
              />
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default LearningResultSection;
