import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../locales/translations';
import { LearningResultItemStatus } from '../types';

type BadgeStatusProps = {
  status: LearningResultItemStatus;
};

const LABELS = {
  [LearningResultItemStatus.Associated]: 'statuses.associated',
  [LearningResultItemStatus.NonAssociates]: 'statuses.non_associated',
};

const BG = {
  [LearningResultItemStatus.Associated]: '#CEF0DA',
  [LearningResultItemStatus.NonAssociates]: '#F2C0BD',
};

const BadgeStatus = ({ status }: BadgeStatusProps) => {
  const { t } = useTranslation(TranslationsKeys.LEARNING_RESULT_LIST);

  return (
    <div className="d-flex w-100 align-items-center justify-content-center">
      <div
        className="d-flex align-items-center justify-content-start"
        style={{ gap: 8, width: 120 }}
      >
        <div
          className="border border-primary"
          style={{ width: 20, height: 20, backgroundColor: BG[status] }}
        />
        <span>{t(LABELS[status])}</span>
      </div>
    </div>
  );
};

export default BadgeStatus;
