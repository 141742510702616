import { OutlinedSelectOptionType } from '@octano/global-ui';
export enum LearningResultItemStatus {
  Associated = 'associated',
  NonAssociates = 'non_associated',
}

export type LearningResultFilters<T> = {
  period?: T;
  section?: T;
  course?: T;
};

export type LearningResultForm = {
  periods: OutlinedSelectOptionType[];
  sections: OutlinedSelectOptionType[];
  courses: OutlinedSelectOptionType[];
};

export type LearningResultItem<T> = {
  period: {
    id: number;
    name: string;
  };
  section: {
    id: number;
    name: string;
  };
  course: {
    id: number;
    code: string;
    name: string;
  };
  school: {
    id: number;
    name: string;
  };
  campus: {
    id: number;
    name: string;
  };
  status: LearningResultItemStatus;
  report?: T;
};

export enum SectionLearningResultTestGradeType {
  Group = 'group',
  TestGrade = 'test_grade',
}

export type LearningResultTestGradeItem = {
  type: SectionLearningResultTestGradeType;
  testGradeId: number | null;
  testGradeGroupId: number | null;
  courseLearningResultId: number | null;
  date: string;
  name: string;
  notes?: string | null;
  file?: File | null;
  linkedFile?: { name: string; url: string } | null;
  overrideFile?: boolean;
  qualitativeAssessments: { key: string; name?: string; value: number }[];
};

export type CourseLearningResult = {
  id: number;
  name: string;
  code: string;
  description: string;
};
