import { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Col, Form, Row } from 'reactstrap';

// Components
import { courseToFormFields } from '../adapter/adapterResponseDetails';
import { CourseFormContext } from '../provider/CourseProvider';
import { CourseFormFields } from '../types/details';

// Sections
import CourseActivityTypeSection from './sections/CourseActivityTypeSection';
import CourseAttendanceSection from './sections/CourseAttendanceSection';
import CourseCompatibilitiesSection from './sections/CourseCompatibilitiesSection';
import CourseDetailsSection from './sections/CourseDetailsSection';
import CourseFooterSection from './sections/CourseFooterSection';
import CourseHeaderSection from './sections/CourseHeaderSection';
import CoursePrerequisitesSection from './sections/CoursePrerequisitesSection';
import CourseSyllabusSection from './sections/CourseSyllabusSection';
import CourseTypeSection from './sections/CourseTypeSection';
import LearningResultSection from './sections/LearningResultSection';

type CourseFormProps = {
  onBack?: () => void;
};

const CourseForm = ({ onBack }: CourseFormProps) => {
  const { course, formData } = useContext(CourseFormContext);

  const methods = useForm<CourseFormFields>();
  const { reset } = methods;

  useEffect(() => {
    if (course && formData) {
      reset(courseToFormFields(course, formData));
    }
  }, [course, formData, reset]);

  return (
    <Form>
      <FormProvider {...methods}>
        <CourseHeaderSection onBack={onBack} />
        <CourseDetailsSection />
        <CourseTypeSection />
        <CourseActivityTypeSection />
        <Row className="pt-4 mb-0">
          <Col xs={12} md={4}>
            <CourseAttendanceSection />
          </Col>
          <Col xs={12} md={8}>
            <CoursePrerequisitesSection />
          </Col>
          <Col xs={12} md={4}>
            <CourseSyllabusSection />
          </Col>
          <Col xs={12} md={8}>
            <CourseCompatibilitiesSection />
          </Col>
        </Row>
        <LearningResultSection />
        <CourseFooterSection />
      </FormProvider>
    </Form>
  );
};

export default CourseForm;
