import { useContext } from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { TextInput, Select } from '@octano/global-ui';

import { TranslationsKeys } from '../../../../locales/translations';
import { CourseFormFields } from '../../types/details';
import { CourseFormContext } from '../../provider/CourseProvider';
import SectionTitle from '../../../../components/Text/SectionTitle';

const CourseDetailsSection = () => {
  const { t } = useTranslation(TranslationsKeys.COURSES_DETAILS);

  const { control } = useFormContext<CourseFormFields>();

  const { formData } = useContext(CourseFormContext);
  const {
    schools = [],
    academicLevels = [],
    modalities = [],
    replaceCourseGradeOptions = [],
    coursesFeatures,
  } = formData;

  return (
    <Row>
      <Col xs={12} className="pt-4 pb-3">
        <SectionTitle text={t(`dataCourse`)} />
      </Col>
      <Col xs={6} lg={4}>
        <TextInput
          name="shortening"
          label={t(`shortening`)}
          control={control}
          disabled
        />
      </Col>
      <Col xs={6} lg={4}>
        <TextInput name="code" label={t(`code`)} control={control} disabled />
      </Col>
      <Col xs={12} lg={4}>
        <TextInput name="name" label={t(`name`)} control={control} disabled />
      </Col>
      <Col xs={12} lg={4}>
        <Select
          name="school"
          label={t(`school`)}
          options={schools}
          control={control}
          disabled
        />
      </Col>
      <Col xs={12} lg={4}>
        <Select
          name="modality"
          label={t(`modality`)}
          options={modalities}
          control={control}
          isClearable={true}
          disabled
        />
      </Col>
      <Col xs={12} lg={4}>
        <Select
          name="academicLevel"
          label={t(`academicLevel`)}
          options={academicLevels}
          control={control}
          disabled
        />
      </Col>
      <Col xs={12} lg={4}>
        <TextInput
          name="credits"
          label={t(`credits`)}
          control={control}
          disabled
        />
      </Col>
      {!!coursesFeatures?.replaceCourseGradeAllowed && (
        <Col xs={12} lg={4}>
          <Select
            name="replaceCourseGradeAllowed"
            label={t(`replaceCourseGradeAllowed`)}
            options={replaceCourseGradeOptions}
            control={control}
            disabled
          />
        </Col>
      )}
    </Row>
  );
};

export default CourseDetailsSection;
