import { format as formatRut } from 'rut.js';

import { StudentGradesMapResponse } from '../../../api/requests/gradeEntry';
import { StudentProps } from '../parts/AddEvaluationGrade/AddEvaluationGradeEntry';

export default function mapStudentsProps(data: StudentGradesMapResponse[]) {
  return data.map<StudentProps>(
    ({
      fullName,
      run,
      passportNumber,
      id: studentId,
      testGrades,
      examGrade,
      partialGrade,
      finalGrade,
      finalForcedGrade,
      partialGradeGroup,
    }) => {
      let _testGrades = [];
      for (const key in testGrades) {
        _testGrades.push(testGrades[key]);
      }
      return {
        fullname: fullName,
        id: (run ? formatRut(run) : passportNumber) as string,
        testGradeGroup:
          partialGradeGroup
            .filter((gradeGroup) => gradeGroup.type === 'subevaluations')
            .map((pGradeGroup) => ({
              id: pGradeGroup?.group?.id,
              grade: pGradeGroup.grade,
              percentage: pGradeGroup.percentage,
            })) ?? [],
        testGrade: `${partialGrade ?? ''}`,
        finalGrade: `${finalGrade ?? ''}`,
        finalForcedGrade: `${finalForcedGrade ?? ''}`,
        noteExamen: `${examGrade.grade ?? ''}`,
        notes: _testGrades.map(
          ({ studentTestGradeId, testGradeId, grade }) => ({
            studentTestGradesId: studentTestGradeId || null,
            studentTestGradesGrade: `${grade ?? ''}`,
            testGradeId,
            studentId,
          }),
        ),
        finalGrades: {
          finalGradeFinalTest: examGrade.studentFinalGradeId,
          finalGradeId: examGrade.finalGradeId,
          studentFinalGradesGrade: `${
            examGrade?.grade ? examGrade?.grade : ''
          }`,
          studentFinalGradesId: examGrade.studentFinalGradeId,
        },
        studentId,
      };
    },
  );
}
