import { BASE } from '../endpoints';
import request from '../request';

export function getBase() {
  const url = BASE.BASE;
  return request<{
    id: number;
    email: string;
    fullname: string;
    isActive: boolean;
    modules: string[];
  }>(url);
}
