import CourseList from './CourseList';
import { CoursesLoader } from './provider/CoursesLoader';

const Courses = () => {
  return (
    <CoursesLoader>
      <CourseList />
    </CoursesLoader>
  );
};

export default Courses;
