import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PathsLayouts } from '../../config/routes';
import CourseForm from './parts/CourseForm';
import CourseProvider from './provider/CourseProvider';

const CourseDetails = () => {
  const { id: courseId } = useParams<{ id: string }>();
  const history = useHistory();

  const handleBack = useCallback(() => {
    history.push(`${PathsLayouts.courses}`);
  }, [history]);

  return (
    <CourseProvider courseId={courseId}>
      <CourseForm onBack={handleBack} />
    </CourseProvider>
  );
};

export default CourseDetails;
