import request from '../../../api/request';
import { SearchParams } from '../types/common';
import {
  GradeReplacementPetitionsForm,
  GradeReplacementPetitionsResponse,
  rowData,
} from '../types/pendingPetitions';

export const GRADE_REPLACEMENT_PETITIONS = {
  LIST: '/teacher-portal/grade-replacement-petitions',
  FORM: '/teacher-portal/grade-replacement-petitions/form',
  DOWNLOAD: '/teacher-portal/grade-replacement-petitions/download',
  PUT: '/teacher-portal/grade-replacement-petitions/:id',
};

export const getGradeReplacementPetitionsList = async (
  filter: { items: number; page: number } & SearchParams,
) => {
  const URL = GRADE_REPLACEMENT_PETITIONS.LIST;
  const method = 'GET';
  const params = { ...filter, items_per_page: filter.items };
  return request<GradeReplacementPetitionsResponse>(URL, { method, params });
};

export const getGradeReplacementPetitionsFormFields = () =>
  request<GradeReplacementPetitionsForm>(GRADE_REPLACEMENT_PETITIONS.FORM);

export const downloadPetitions = async (
  filter: { items: number; page: number } & SearchParams,
) => {
  const data = { ...filter, items_per_page: filter.items };
  return request<any>(GRADE_REPLACEMENT_PETITIONS.DOWNLOAD, {
    data,
    method: 'POST',
    responseType: 'blob',
  });
};

export const updateGradeReplacementPetition = async (rowData: rowData) => {
  const URL = GRADE_REPLACEMENT_PETITIONS.PUT.replace(
    ':id',
    rowData.folio.toString(),
  );
  return request<{ replacementGrade: string }>(URL, {
    method: 'PUT',
    data: rowData,
  });
};
