import { Button, SearchBox, useMobile } from '@octano/global-ui';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import { useValidations } from '../../../hooks/useValidations';
import { TranslationsKeys } from '../../../locales/translations';

export type SearchParams = {
  code?: string;
  name?: string;
  hasFilters?: boolean;
};

const dictPrefix = 'filters';

const DEFAULT_VALUES = {
  code: '',
  name: '',
};

interface FormValues {
  code: string;
  name: string;
}

type onSearch = {
  searchParams: SearchParams;
  page: number;
};

interface SearchControlsProps {
  onSearch?: ({ searchParams, page }: onSearch) => void;
  onClear?: () => void;
  setFilters?: (params: any) => void;
}

export function SearchControls({
  onSearch = () => null,
  onClear = () => null,
  setFilters = (params: any) => {},
}: SearchControlsProps) {
  const { t } = useTranslation(TranslationsKeys.COURSES_LIST);

  const isMobile = useMobile();
  const { validateMinLength } = useValidations();

  const { handleSubmit, control, reset } = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
  });

  const handleSearch = (values: FormValues) => {
    const searchParams = {
      code: values.code?.trim() !== '' ? values.code : undefined,
      name: values.name?.trim() !== '' ? values.name : undefined,
      hasFilters: Object.values(values).some((value) => value !== null),
    };

    setFilters({
      ...searchParams,
    });

    onSearch({ searchParams, page: 1 });
  };

  const handleClear = () => {
    reset(DEFAULT_VALUES);
    onClear();
    onSearch({
      searchParams: {},
      page: 1,
    });
  };

  return (
    <Form onSubmit={handleSubmit(handleSearch)}>
      <Row className="pb-4 gutters-filters">
        <Col xs={12} md={6} lg={3}>
          <SearchBox
            name="name"
            label={t(`${dictPrefix}.name`)}
            placeholder={t(`${dictPrefix}.placeholderName`)}
            control={control}
            rules={{
              validate: {
                minLength: validateMinLength(3),
              },
            }}
          />
        </Col>

        <Col xs={12} md={6} lg={3}>
          <SearchBox
            name="code"
            label={t(`${dictPrefix}.code`)}
            placeholder={t(`${dictPrefix}.placeholderCode`)}
            control={control}
            rules={{
              validate: {
                minLength: validateMinLength(3),
              },
            }}
          />
        </Col>

        <Col
          xs={12}
          md={{ size: 3, offset: 6 }}
          lg={{ size: 2, offset: 2 }}
          style={{ marginTop: isMobile ? 0 : '1.5rem' }}
        >
          <Button
            type="submit"
            text={t(`translation:common.actions.search`)}
            size="md"
            fullwidth
          />
        </Col>

        <Col
          xs={12}
          md={3}
          lg={2}
          style={{ marginTop: isMobile ? 0 : '1.5rem' }}
        >
          <Button
            text={t(`translation:common.actions.clean`)}
            size="md"
            fullwidth
            outlined
            onClick={handleClear}
          />
        </Col>
      </Row>
    </Form>
  );
}
