import { Button } from '@octano/global-ui';
import { useCallback, useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import SectionTitle from '../../../../components/Text/SectionTitle';
import { TranslationsKeys } from '../../../../locales/translations';
import { CourseFormContext } from '../../provider/CourseProvider';
import { CourseFormFields } from '../../types/details';

const CourseSyllabusSection = () => {
  const { t } = useTranslation(TranslationsKeys.COURSES_DETAILS);

  const { watch } = useFormContext<CourseFormFields>();

  const { course } = useContext(CourseFormContext);

  const [syllabusFileInput] = watch(['syllabusFile']);

  const canDownloadSyllabus = useMemo(
    () => syllabusFileInput?.lastModified === -2,
    [syllabusFileInput?.lastModified],
  );

  const handleDownloadSyllabus = useCallback(() => {
    if (!course?.syllabus?.url?.trim()) {
      return;
    }
    window.open(course?.syllabus?.url?.trim(), '_blank');
  }, [course?.syllabus?.url]);

  return (
    <Row>
      <Col xs={12} className="pt-4 pb-4">
        <SectionTitle text={t(`syllabusTitle`)} />
      </Col>
      {canDownloadSyllabus ? (
        <Col xs={12}>
          <Button
            text={t(`syllabusDownload`)}
            icon="download"
            outlined
            size="md"
            className="w-100"
            disabled={!canDownloadSyllabus}
            onClick={canDownloadSyllabus ? handleDownloadSyllabus : undefined}
          />
        </Col>
      ) : (
        <></>
      )}
    </Row>
  );
};

export default CourseSyllabusSection;
