import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Container } from 'reactstrap';
import TabControl from '../../components/tabs/TabControl';

import { PathsLayouts } from '../../config/routes';
import { TranslationsKeys } from '../../locales/translations';
import CompletedPetitions from './completedPetitions';
import CPetitionsTableLoader from './completedPetitions/parts/PetitionsTableLoader';
import PPetitionsTableLoader from './pendingPetitions/parts/PetitionsTableLoader';

import PendingPetitions from './pendingPetitions';

export default function GradeReplacementPetitions() {
  const { t } = useTranslation(TranslationsKeys.PETITIONS);

  const text = useMemo(() => {
    return {
      description: t(`description`),
    };
  }, [t]);

  const tabs = useMemo(
    () => [
      {
        name: 'pending',
        literalName: t(`tabs.pending`),
        content: (
          <PPetitionsTableLoader>
            <PendingPetitions />
          </PPetitionsTableLoader>
        ),
      },
      {
        name: 'completed',
        literalName: t(`tabs.completed`),
        content: (
          <CPetitionsTableLoader>
            <CompletedPetitions />
          </CPetitionsTableLoader>
        ),
      },
    ],
    [t],
  );

  return (
    <Container fluid>
      <Card className="">
        <p className="fs-18 text-light mb-5 mt-3 p-4">{text.description}</p>
        <TabControl tabs={tabs} path={PathsLayouts.gradeReplacementPetitions} />
      </Card>
    </Container>
  );
}
