import { useContext } from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import { CheckInput } from '@octano/global-ui';

import SectionTitle from '../../../../components/Text/SectionTitle';
import { TranslationsKeys } from '../../../../locales/translations';
import { CourseFormContext } from '../../provider/CourseProvider';
import { CourseFormFields } from '../../types/details';

const CourseTypeSection = () => {
  const { t } = useTranslation(TranslationsKeys.COURSES_DETAILS);
  const { control } = useFormContext<CourseFormFields>();

  const { formData } = useContext(CourseFormContext);
  const { courseTypes = [] } = formData;

  if (!courseTypes?.length) {
    return <></>;
  }

  return (
    <Row>
      <Col xs={12} className="pt-4">
        <SectionTitle text={t(`courseTypes`)} />
      </Col>
      <Col xs={12}>
        <Row className="px-md-3 py-2">
          <Controller
            control={control}
            name="courseTypes"
            render={({ field }) => (
              <>
                {courseTypes.map((type) => (
                  <Col xs={6} md={4} lg={3} xl={2} key={`course_${type.value}`}>
                    <CheckInput
                      name={`course_type_${type.value}`}
                      label={type.label}
                      value={!!field?.value?.includes(+type.value)}
                      disabled
                    />
                  </Col>
                ))}
              </>
            )}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default CourseTypeSection;
