import { LEARNING_RESULTS } from '../../api/endpoints';
import request from '../../api/request';
import {
  PaginationQueryDto,
  PaginationRequestType,
} from '../../types/paginationRequestType';
import {
  CourseLearningResult,
  LearningResultFilters,
  LearningResultForm,
  LearningResultItem,
} from './types';

export const getLearningResultsForm = () =>
  request<LearningResultForm>(LEARNING_RESULTS.FORM);

export const getPaginatedLearningResults = (
  params: PaginationQueryDto<LearningResultFilters<string>>,
) =>
  request<PaginationRequestType<LearningResultItem<undefined>>>(
    LEARNING_RESULTS.GET_PAGINATED_ITEMS,
    { params },
  );

export const getItemsToAssociateForm = (sectionId: string | number) =>
  request<CourseLearningResult[]>(LEARNING_RESULTS.ASSOCIATE_FORM(sectionId));

export const getItemsToAssociate = (sectionId: string | number) =>
  request<any>(LEARNING_RESULTS.ASSOCIATE(sectionId));

export const associateItems = (sectionId: string | number, data: FormData) =>
  request<void>(LEARNING_RESULTS.ASSOCIATE(sectionId), {
    method: 'POST',
    data,
  });

export function downloadSectionReport(sectionId: string | number) {
  const method = 'GET';
  return request(LEARNING_RESULTS.DOWNLOAD_SECTION(sectionId), {
    responseType: 'blob',
    method,
  });
}
