import { ReactElement, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { AxiosResult, AxiosResultDefaultError } from '../../../../api/request';
import { createFetchContext } from '../../../../components/contexts/FetchContextFactory';
import { AuthenticationError } from '../../../GradeEntry/Loaders/CreateEvaluationGradeEntryBaseLoader';
import { getGradeReplacementPetitionsList as getPaginationBase } from '../../api/pendingPetitions';
import { PetitionGradeProcessStatus } from '../../enums/petition-grade-process-status.enum';
import { ITEMS_PER_PAGE_DEFAULT, HandleSearch } from '../../types/common';
import { GradeReplacementPetitionsResponse } from '../../types/pendingPetitions';

interface BaseLoaderProps {
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  HandleSearch,
  GradeReplacementPetitionsResponse,
  AuthenticationError | AxiosResultDefaultError
>();

export function usePetitionsTableLoader() {
  const context = useFetch();

  return {
    error: context.error,
    loading: context.loading,
    data: context.data,
    query: context.query,
    updateQuery: context.updateQuery,
  };
}

export const PetitionsTableLoaderConsumer = FetchConsumer;

export default function PetitionsTableLoader({ children }: BaseLoaderProps) {
  const location = useLocation();
  const selectedTab = location.pathname.split('/').pop();

  const request = useCallback(async (query: HandleSearch): Promise<
    AxiosResult<GradeReplacementPetitionsResponse, AxiosResultDefaultError>
  > => {
    return getPaginationBase({
      items: ITEMS_PER_PAGE_DEFAULT,
      page: query.page - 1,
      gradeProcessStatus: PetitionGradeProcessStatus.Pending,
      ...query?.searchParams,
    });
  }, []);

  return (
    <FetchProvider
      request={request}
      defaultQuery={{ page: 1 }}
      fetchImmediately={selectedTab === PetitionGradeProcessStatus.Pending}
    >
      {selectedTab === PetitionGradeProcessStatus.Pending && children}
    </FetchProvider>
  );
}
