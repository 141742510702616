import { getSelectedOption } from '../../../utils/selectFormat';
import {
  CourseFormData,
  CourseFormFields,
  replaceCourseGradeAllowedCode,
  Course,
  CourseSyllabus,
} from '../types/details';

export const syllabusToFile = (
  syllabus?: CourseSyllabus | null,
): File | null => {
  if (!syllabus) {
    return null;
  }
  return new File([] as any, `${syllabus?.name}.${syllabus?.extension}`, {
    lastModified: -2,
  });
};

export const courseToFormFields = (
  course: Course,
  {
    schools = [],
    academicLevels = [],
    modalities = [],
    replaceCourseGradeOptions = [],
  }: CourseFormData,
): CourseFormFields => {
  return {
    // CourseDetailsSection
    shortening: course.shortening,
    code: course.code,
    name: course.name,
    credits: course.credits.toString(),
    school: getSelectedOption(course.schoolId, schools),
    academicLevel: getSelectedOption(course.academicLevelId, academicLevels),
    modality: getSelectedOption(course.modalityId ?? 0, modalities),
    replaceCourseGradeAllowed: getSelectedOption(
      course.replaceCourseGradeAllowed
        ? replaceCourseGradeAllowedCode.Enabled
        : replaceCourseGradeAllowedCode.Disabled,
      replaceCourseGradeOptions,
    ),
    // CourseTypeSection
    courseTypes: course?.types?.map((e) => e.id) ?? [],
    // CourseActivityTypeSection,
    attendance:
      course?.assistanceRequirements?.map((e) => ({
        minPercentage: e.minPercentage,
        activityTypeId: e.activityTypeId,
      })) ?? [],
    // CourseSyllabusSection
    syllabusFile: syllabusToFile(course?.syllabus),
    // CourseCompatibilitiesSection
    compatibilities:
      course?.compatibilities?.map((e) => ({
        id: e.id,
        shortening: e.shortening,
        name: e.name,
        credits: e.credits,
      })) ?? [],
    // CoursePrerequisitesSection
    prerequisites:
      course?.dependsOn?.map((e) => ({
        id: e.id,
        shortening: e.shortening,
        name: e.name,
        credits: e.credits,
      })) ?? [],
    learningResults:
      course.courseLearningResults?.map((e, index) => ({
        id: e.id,
        code: e.code,
        description: e.description,
      })) ?? [],
  };
};
