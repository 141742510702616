import {
  LearningResultTestGradeItem,
  SectionLearningResultTestGradeType,
} from './types';

export const createFormData = ({
  notes,
  items,
}: {
  notes?: string | null;
  items: LearningResultTestGradeItem[];
}) => {
  const formData = new FormData();
  if (notes?.trim()) {
    formData.append('notes', notes);
  }
  items.forEach((item, index) => {
    if (item.testGradeGroupId) {
      formData.append(
        `items[${index}][testGradeGroupId]`,
        item.testGradeGroupId.toString(),
      );
      formData.append(
        `items[${index}][type]`,
        SectionLearningResultTestGradeType.Group,
      );
    } else if (item.testGradeId) {
      formData.append(
        `items[${index}][testGradeId]`,
        item.testGradeId.toString(),
      );
      formData.append(
        `items[${index}][type]`,
        SectionLearningResultTestGradeType.TestGrade,
      );
    }

    if (item.notes?.trim()) {
      formData.append(`items[${index}][notes]`, item.notes?.trim());
    }
    if (item.file) {
      formData.append(`items[${index}][hasFileToUpload]`, '1');
    }
    if (item.overrideFile) {
      formData.append(`items[${index}][overrideFile]`, '1');
    }
    if (item.courseLearningResultId) {
      formData.append(
        `items[${index}][courseLearningResultId]`,
        item.courseLearningResultId.toString(),
      );
    }
    if (item.file) {
      formData.append(`files`, item.file);
    }
  });
  return formData;
};
