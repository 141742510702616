import { ReactElement, useCallback } from 'react';
import { createFetchContext } from '../../../components/contexts/FetchContextFactory';
import { getCoursesTeacherPortalRequest as getBase } from '../api';
import {
  CoursesTableQuery as BaseQuery,
  CoursesTableResponse as Base,
} from '../types';

export const ITEMS_PER_PAGE_DEFAULT = 10;

interface BaseLoaderProps {
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  BaseQuery,
  Base
>();

export function useCoursesLoader() {
  const context = useFetch();
  return {
    data: context.data,
    query: context.query,
    loading: context.loading,
    updateQuery: context.updateQuery,
  };
}

export const CoursesLoaderConsumer = FetchConsumer;

export function CoursesLoader({ children }: BaseLoaderProps) {
  const request = useCallback(({ page, name, code }) => {
    return getBase({
      items: ITEMS_PER_PAGE_DEFAULT,
      page: page - 1,
      code,
      name,
    });
  }, []);

  return (
    <FetchProvider
      request={request}
      defaultQuery={{ page: 1 }}
      fetchImmediately
      children={children}
    />
  );
}
