import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal, addToast, Button } from '@octano/global-ui';
import { Container, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../../locales/translations';
import ChangeGradeForm, { ChangeGradeFormData } from './ChangeGradeForm';
import { useForm, FormProvider } from 'react-hook-form';
import { rowData } from '../../types/pendingPetitions';

export interface ChangeGradeModalRef {
  openModal: (rowData: rowData) => void;
  closeModal: () => void;
}

interface ChangeGradeModalProps {
  onSubmit: (rowData: rowData) => Promise<void>;
}

const ChangeGradeModal = forwardRef<ChangeGradeModalRef, ChangeGradeModalProps>(
  ({ onSubmit }, ref) => {
    const { t } = useTranslation(TranslationsKeys.PETITIONS);
    const [modal, setModal] = useState(false);
    const [rowData, setRowData] = useState<rowData>({
      folio: 0,
      studentName: '',
      typeName: '',
      courseName: '',
      sectionName: '',
      title: '',
      grade: '',
      replacementGrade: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    const methods = useForm<ChangeGradeFormData>({
      defaultValues: {
        newGrade: '',
        studentName: '',
        typeName: '',
        courseName: '',
        sectionName: '',
        title: '',
        grade: '',
      },
      mode: 'onChange',
    });

    useImperativeHandle(ref, () => ({
      openModal: (data: rowData) => {
        setRowData(data);
        methods.reset({
          studentName: data.studentName || '',
          typeName: data.typeName || '',
          courseName: data.courseName || '',
          sectionName: data.sectionName || '',
          title: data.title || '',
          grade: data.grade || '',
          newGrade: '',
        });
        setModal(true);
      },
      closeModal: () => setModal(false),
    }));

    const handleSave = async () => {
      const valid = await methods.trigger();
      if (!valid) return;

      const formData = methods.getValues();
      setIsLoading(true);
      try {
        await onSubmit({ ...rowData, replacementGrade: formData.newGrade });
        addToast({
          icon: 'success',
          color: 'success',
          text: t('modals.changeGrade.success'),
        });
        setModal(false);
      } catch (error) {
        console.error('Error updating grade:', error);
        addToast({
          icon: 'error',
          color: 'danger',
          text: t('modals.changeGrade.error'),
        });
      } finally {
        setIsLoading(false);
      }
    };

    const handleCancel = () => {
      setModal(false);
    };

    const isHabilitaciones = rowData.typeName === 'Habilitaciones';

    return (
      <Modal isOpen={modal} toggle={() => setModal(!modal)} size="lg">
        <Container fluid>
          <p className="text-dark fs-20 mt-3 mb-4 text-center">
            {t('modals.changeGrade.title')}
          </p>
          <p className="fs-16 mt-3 mb-4 mx-5 px-5 text-center">
            {isHabilitaciones
              ? t('modals.changeFinalGrade.description')
              : t('modals.changeGrade.description')}
          </p>
          <FormProvider {...methods}>
            <form
              id="changeGradeForm"
              onSubmit={methods.handleSubmit(() => {})}
            >
              <ChangeGradeForm />
            </form>
            <Row>
              <Col xs={12} md={6}>
                <Button
                  fullwidth
                  text={t('modals.changeGrade.cancel')}
                  onClick={handleCancel}
                />
              </Col>
              <Col xs={12} md={6}>
                <Button
                  fullwidth
                  text={t('modals.changeGrade.confirm')}
                  onClick={handleSave}
                  loading={isLoading}
                  disabled={isLoading}
                />
              </Col>
            </Row>
          </FormProvider>
        </Container>
      </Modal>
    );
  },
);

export default ChangeGradeModal;
